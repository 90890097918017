import React, { useEffect, useState } from 'react'
import Head from 'react-helmet'
import useFirebase from './useFirebase'
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth'

import PageContainer from '../components/Layout/PageContainer'
import {
  Typography,
  Container,
  createStyles,
  withStyles,
} from '@material-ui/core'

import logo from '../images/logo.png'

const styles = (theme) =>
  createStyles({
    subtitleText: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 700,
      color: '#F0EDEF',
      marginBottom: 35,
    },
    imageContainer: {
      width: 150,
      margin: '0 auto 50px',
    },
    image: {
      width: '100%',
    },
    container: {
      marginTop: 50,
      width: 290,
      margin: '0 auto',
    },
    label: {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 10,
      textAlign: 'left',
      color: '#F0EDEF',
    },
    fieldContainer: {
      marginBottom: 35,
      width: 290,
    },
    fieldInput: {
      boxShadow: 'none',
      appearance: 'none',
      width: 285,
      color: '#000000',
      fontSize: 11,
      fontWeight: 500,
      borderRadius: 3,
      borderColor: '#E1E1E1',
      borderWidth: 1,
      padding: '6px 10px',
      border: '0.0187rem solid rgba(255,0,128,1)',

      '&::placeholder': {
        color: '#C8C2C2'
      }
    },
    error: {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 10,
      textAlign: 'left',
      color: '#FF0020',
      position: 'absolute',
      top: -30,
    },
    confirmText: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'left',
      color: '#F0EDEF',
      lineHeight: 2,
    },
    confirmTextBold: {
      fontWeight: 700,
    },
    buttonWrapper: {
      marginTop: 50,
      position: 'relative',
    },
    resetButton: {
      backgroundColor: '#FF0080',
      color: '#ffffff',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 14,
      width: 220,
      borderRadius: 5,
      borderWidth: 0,
      cursor: 'pointer',
      padding: '8px 0'
    }
  })

const PasswordReset = ({ classes }) => {
  const firebase = useFirebase()

  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [resetCode, setResetCode] = useState('')

  useEffect(() => {
    if (!firebase) return
    checkForCode()
  }, [firebase])

  const checkForCode = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('oobCode')

    if (!code) {
      window.location.replace("https://avocadofinance.io")
      return
    }
    if (code) {
      setResetCode(code)
    }
  }

  const resetPassword = async () => {
    const auth = getAuth()
    setError('')

    if (password.length < 8) {
      setError('Password must be 8 characters long.')
      return
    }

    if (password !== confirmedPassword) {
      setError('Passwords do not match.')
      return
    }

    await verifyPasswordResetCode(auth, resetCode)
      .then(async email => {
        console.info('success: ')
        console.info(email)
        await setEmail(email)

        await confirmPasswordReset(auth, resetCode, confirmedPassword)
          .then((res) => {
            console.info(res)
            console.info('it was successful')
            setSuccess(true)
          })
          .catch((error) => {
            console.error(error)
            setError('Could not reset password due to an error.')
          })
      })
      .catch(error => {
        console.info('error: ')
        console.error(error)
        setError('Invalid email code.')
      })
  }

  const SuccessState = () => (
    <div>
      <Typography className={classes.subtitleText}>
        Password updated
      </Typography>
      <p className={classes.confirmText}>
        Please use your new password to log into Avocado Finance.
      </p>
    </div>
  )

  return (
    <PageContainer>
      <Head>
        <title>Password Reset</title>
      </Head>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={logo}
            alt='Avocado Finance Logo'
          />
        </div>
        {
          success
            ? <SuccessState />
            : (
              <div>
                <Typography className={classes.subtitleText}>
                  Change Your Password
                </Typography>
                <div className={classes.fieldContainer}>
                  <Typography className={classes.label}>
                    New password
                  </Typography>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                    placeholder="New password"
                    className={classes.fieldInput}
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <Typography className={classes.label}>
                    Confirm new password
                  </Typography>
                  <input
                    type="password"
                    value={confirmedPassword}
                    onChange={(e) => {
                      setConfirmedPassword(e.target.value)
                    }}
                    placeholder="Confirm new password"
                    className={classes.fieldInput}
                  />
                </div>
                <div className={classes.buttonWrapper}>
                  {
                    error && (
                      <Typography className={classes.error}>
                        {error}
                      </Typography>
                    )
                  }
                  <button
                    type="button"
                    className={classes.resetButton}
                    onClick={() => {
                      resetPassword()
                    }}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            )
        }
      </div>
    </PageContainer>
  )
}

export default withStyles(styles)(PasswordReset)
