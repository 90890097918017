import * as firebase from 'firebase/app'
import 'firebase/auth'

// Firebase web config
const config = {
  apiKey: 'AIzaSyBiqVmYfrRHiu325EJM5xQO3XcnejZqrls',
  authDomain: 'af-staging-8e70f.firebaseapp.com',
  databaseURL: 'https://project-id.firebaseio.com',
  projectId: 'af-staging-8e70f',
  storageBucket: 'af-staging-8e70f.appspot.com',
  appId: '1:732446053047:ios:8d88ec5365cb838de7f332',
}

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    console.info(instance)
    return instance;
  }

  return null;
}